<template>
    <div class="wrap">
        <CancelModal v-if="cancelStatus" />
        <popup-modal v-if="isPopUpModal" :text="popupModalText" @close-modal="onCloseModal" />
        <CancelCheckModal
            v-if="cancelCheckStatus"
            :curationinfo="curationObject"
            :isRegistCard="isRegistCard"
            @closeCancel="cancelCheckStatus = false"
            @showCancel="cancelStatus = true"
            @deletspace="onDeleteSpace"
        />
        <!--################ PC ################-->
        <div class="space_pc">
            <CurationInfo
                :curationInfo="curationInfo"
                v-if="showCurationInfo"
                @closeCurationInfo="showCurationInfo = false"
            />
            <section class="main myspace h_100 w_940">
                <div class="main_box my_space3">
                    <div class="main_box_title">
                        <h1 class="mb_0px">스페이스 등록</h1>
                        <div @click="linkMySpace()">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>
                    <div class="myspace_content_form qration">
                        <div class="form_text">
                            <h3 class="mb_0px">국내곡 비율은 어느 정도로 해드릴까요?</h3>
                        </div>
                        <div class="pagnation">
                            <span></span>
                            <span :style="{ width: `${progressbarStatus}` }"></span>
                        </div>
                        <div class="three_type fade-up">
                            <div class="graph_bar">
                                <span id="progress" :style="{ width: domesticRate + '%' }"></span>
                                <div class="span_box">
                                    <span
                                        class="graph_dot bg_none"
                                        v-for="item in countryRateList"
                                        :key="item.id"
                                        @click="parseInt(item.value) < 90 ? clickRate(item.value) : showInfo2()"
                                    >
                                    </span>
                                </div>
                            </div>
                            <div class="graph_bar_txt">
                                <p>
                                    국내 <span>{{ domesticRate }}</span
                                    >%
                                </p>
                                <p>
                                    해외 <span>{{ foreignRate }}</span
                                    >%
                                </p>
                            </div>
                        </div>
                        <div class="notice_txt" :class="{ active2: fadeOut }">클릭한 비율 위주로 큐레이션 됩니다.</div>
                        <footer class="insert_bottom dp_inlineblock box_contents">
                            <ul class="insert_bottom_spaceinfo">
                                <li class="align_center">
                                    <svg
                                        width="36"
                                        height="36"
                                        viewBox="0 0 36 36"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        @click="showCurationInfo = true"
                                    >
                                        <path
                                            d="M18.636 16.828L13.686 21.778L12.272 20.364L18.636 14L25 20.364L23.586 21.778L18.636 16.828Z"
                                            fill="white"
                                        />
                                    </svg>
                                    <div class="space_curation_bottom">
                                        <div
                                            class="none"
                                            v-for="(item, index) in bottomList"
                                            :key="`bottom${index}`"
                                            :style="`z-index:${bottomList.length - index}`"
                                        >
                                            <label v-if="item.cssclass === 'space_curation_info_box material'" :class="item.cssclass">
                                                {{ item.url }}
                                            </label>
                                            <figure v-else :class="item.cssclass">
                                                <img class="curation_info_box_img" :src="item.url" />
                                            </figure>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <ul class="insert_bottom_spaceinfo">
                                        <button class="insert_page_btn prev" :disabled="btnDisabled" @click="clickPre()">이전</button>
                                        <button class="insert_page_btn next" :disabled="btnDisabled" @click="updateCuration()">다음</button>
                                    </ul>
                                </li>
                            </ul>
                        </footer>
                    </div>
                </div>
            </section>
        </div>
        <!--################ Mobile ################-->
        <div class="space_mo h_100">
            <CurationInfoM
                :curationInfo="curationInfo"
                v-if="showCurationInfoM"
                @closeCurationInfo="showCurationInfoM = false"
            />
            <div class="mo_space_header">
                <div class="mo_header_box">
                    <a>
                        <img src="/media/images/icon_back_24.svg" @click="clickPre()" />
                    </a>
                    <h2>스페이스 등록</h2>
                    <div class="btn_close" @click="linkMySpace()">
                        <img src="/media/images/icon_close_24.svg" />
                    </div>
                </div>
                <div class="pagnation">
                    <span></span>
                    <span :style="{ width: `${progressbarStatus}` }"></span>
                </div>
            </div>
            <section class="main registration">
                <div class="main_box">
                    <div class="myspace_content_form">
                        <div class="form_text">
                            <h3 class="mb_0px">국내곡 비율은 어느 정도로 해드릴까요?</h3>
                        </div>
                        <div class="three_type">
                            <div class="graph_bar" style="margin-bottom: 6px">
                                <span id="progress2" :style="{ width: domesticRate + '%' }"></span>
                                <div class="span_box span_box2">
                                    <span
                                        class="graph_dot bg_none"
                                        v-for="item in countryRateList"
                                        :key="item.id"
                                        @click="parseInt(item.value) < 90 ? clickRate(item.value) : showInfo2()"
                                    >
                                    </span>
                                </div>
                            </div>
                            <div class="graph_txt">
                                <p>
                                    국내 <span>{{ domesticRate }}</span
                                    >%
                                </p>
                                <p>
                                    해외<span>{{ foreignRate }}</span
                                    >%
                                </p>
                            </div>
                        </div>
                        <div class="notice_txt mb_45px" :class="{ active2: fadeOut }">클릭한 비율 위주로 큐레이션 됩니다.</div>
                    </div>
                    <ul class="mo_insert_bottom_spaceinfo">
                        <li class="align_center">
                            <svg
                                @click="showInfo('country')"
                                class="mo_curation_arrow"
                                data-v-2b89911a=""
                                width="14"
                                height="8"
                                viewBox="0 0 14 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    data-v-2b89911a=""
                                    d="M7.3025 2.92566L2.3525 7.87566L0.938504 6.46166L7.3025 0.0976563L13.6665 6.46166L12.2525 7.87566L7.3025 2.92566Z"
                                    fill="white"
                                ></path>
                            </svg>
                            <div class="mo_space_curation_bottom" v-if="bottomList.length < 8">
                                <div
                                    class="none"
                                    v-for="(item, index) in bottomList"
                                    :key="`bottom${index}`"
                                    :style="`z-index:${bottomList.length - index}`"
                                >
                                    <label v-if="item.cssclass === 'space_curation_info_box material'" :class="item.cssclass">
                                        {{ item.url }}
                                    </label>
                                    <figure v-else :class="item.cssclass">
                                        <img class="curation_info_box_img" :src="item.url" />
                                    </figure>
                                </div>
                            </div>
                            <div class="mo_space_curation_bottom" v-else>
                                <div
                                    class="none"
                                    v-for="(item, index) in bottomList.slice(0, 8)"
                                    :key="`bottom${index}`"
                                    :style="`z-index:${bottomList.length - index}`"
                                >
                                    <label v-if="item.cssclass === 'space_curation_info_box material'" :class="item.cssclass">
                                        {{ item.url }}
                                    </label>
                                    <figure v-else :class="item.cssclass">
                                        <img class="curation_info_box_img" :src="item.url" />
                                    </figure>
                                </div>
                                <div class="mo_more_spaceinfo_wrap">
                                    <span class="mo_more_spaceinfo"></span>
                                    <span class="mo_more_spaceinfo"></span>
                                    <span class="mo_more_spaceinfo"></span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <footer class="insert_bottom country">
                        <ul class="insert_bottom_box country">
                            <button class="insert_page_btn" :disabled="btnDisabled" @click="updateCuration()">다음</button>
                        </ul>
                    </footer>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { getImg } from '@/assets/js/spaceBottom.js';
import CurationInfo from '@/components/modal/Space/CurationInfo.vue';
import CancelCheckModal from '@/components/modal/Space/CancelCheckModal.vue';
import CancelModal from '@/components/modal/Space/CancelModal.vue';
import CurationInfoM from '@/components/modal/Space/CurationInfoM.vue';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import router from '@/router';
import PopUpModal from '@/components/modal/common/PopUpModal.vue';
import { setMappingCuration, updateConfirmCuration } from '@/service/api/profileApi';
export default defineComponent({
  data () {
    return {
      curationObject: {},
      btnDisabled: false,
      isInstalledPlayer: false,
      isRegistCard: true,
      cancelStatus: false,
      cancelCheckStatus: false,
      showCurationInfo: false,
      showCurationInfoM: false,
      fadeOut: false,
      domesticRate: '50',
      foreignRate: '50',
      domesticRateNm: '국내 50%',
      foreignRateNm: '해외 50%',
      curationList: [],
      curationInfo: {
        infoStatus: '',
        infoCategory: '',
        mood: '',
        target: [],
        unit: [],
        carPurpose: [],
        carMood: ''
      },
      bottomList: [],
      popupModalText: '',
      isPopUpModal: false,
      countryRateList: [],
      spaceType: 'Store',
      progressbarStatus: '0%'
    };
  },
  components: {
    CurationInfo,
    CancelModal,
    CancelCheckModal,
    CurationInfoM,
    'popup-modal': PopUpModal
  },
  methods: {
    onDeleteSpace (msg) {
      this.isPopUpModal = true;
      this.popupModalText = msg;
    },
    checkRegistCard () {
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/product/getMyCardInfo', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            result != null ? (this.isRegistCard = true) : (this.isRegistCard = false);
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    showInfo2 () {
      this.fadeOut = true;

      setTimeout(() => {
        this.fadeOut = false;
        var noticeTxt = document.getElementsByClassName('notice_txt');
        noticeTxt[0].innerText = '국내곡 비율은 최대 80%까지 선택 가능합니다.';
        noticeTxt[1].innerText = '국내곡 비율은 최대 80%까지 선택 가능합니다.';
      }, 300);
    },
    showInfo (index) {
      this.curationInfo.infoStatus = index;
      this.curationInfo.infoCategory = 'insert';
      this.showCurationInfoM = true;
    },
    linkMySpace () {
      var spaceId = localStorage.getItem('spaceId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/checkSubscribe', { spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            if (res.data.result === 'S') {
              this.cancelCheckStatus = true;
              this.curationObject = this.filterCurationInfo();
            } else {
              router.push({ name: 'Player' });
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    filterCurationInfo () {
      const spaceId = localStorage.getItem('spaceId');
      const domesticRate = this.domesticRate;
      const foreignRate = this.foreignRate;
      const period = [4, 5].join(',');
      return { spaceId, domesticRate, foreignRate, period };
    },
    async updateCurationStore () {
      const filter = this.filterCurationInfo();
      try {
        const result = await setMappingCuration(filter.spaceId, filter.domesticRate, filter.foreignRate);

        const { resultCd: mappingCurationResultCd } = result.data;
        if (mappingCurationResultCd === '0000') {
          const { data } = await updateConfirmCuration(filter.spaceId);
          const { resultCd: confirmResultCd } = data;
          if (confirmResultCd === '0000') {
            this.$router.push({ name: 'SpaceInsertGenre' }).catch(() => {});
          }
        }
      } catch (error) {
        console.log('updateCuration error : ', error);
        if (error.response) {
          // eslint-disable-next-line no-undef
          const { data } = err.response;
          const { resultCd, resultMsg } = data;
          if (resultCd === '400' && resultMsg === '삭제된 스페이스입니다.') {
            this.popupModalText = resultMsg;
            this.isPopUpModal = true;
          }
        }
      } finally {
        this.btnDisabled = false;
      }
    },
    async updateCurationCar () {
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      const filter = this.filterCurationInfo();

      axios
        .post('/api/profile/updateCurationInfo', filter, { headers })
        .then(() => {
          axios
            .post('/api/profile/updateCurationTemp', filter, { headers })
            .then(async () => {
              this.$router.push({ name: 'SpaceInsertGenre' }).catch(() => {});
            })
            .catch(err => {
              if (err.response) {
                const { data } = err.response;
                const { resultCd, resultMsg } = data;
                if (resultCd === '400' && resultMsg === '삭제된 스페이스입니다.') {
                  this.popupModalText = resultMsg;
                  this.isPopUpModal = true;
                  return false;
                }
              }
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },
    async updateCuration () {
      this.btnDisabled = true;

      if (this.curationInfo.carMood != null) {
        // 차량
        this.updateCurationCar();
      } else {
        this.updateCurationStore();
      }
    },
    onCloseModal () {
      this.isPopUpModal = false;
      this.$router.push({ name: 'MySpace' });
    },
    failUpdateCuration () {
      alert('선호음악 저장에 실패하였습니다.');
      this.btnDisabled = false;
    },
    clickPre () {
      if (this.spaceType === 'Store' || this.spaceType === 'Building') {
        this.$router.push({ name: 'SpaceInsertStoreTarget' }).catch(() => {});
      } else {
        this.$router.push({ name: 'SpaceInsertCarPurpose' }).catch(() => {});
      }
    },
    clickRate (rate) {
      this.domesticRate = parseInt(rate);
      this.foreignRate = parseInt(100 - this.domesticRate);
    },
    getCurationInfo () {
      var spaceId = localStorage.getItem('spaceId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/getCurationInfo', { spaceId }, { headers })
        .then(res => {
          let stepList = null;
          this.spaceType = res.data.result.spaceType;
          if (res.data.result.spaceType === 'Car') {
            stepList = [true, true, false, false, false, false];

            this.bottomList = getImg(res.data.result, stepList).map(item => {
              return {
                cssclass: 'space_curation_info_box img',
                url: item
              };
            });
            this.progressbarStatus = '48%';
          } else {
            this.progressbarStatus = '60%';

            // 고객층
            if (res.data.result.target) {
              res.data.result.target.forEach(ele => {
                const findTarget = this.$store.getters['curation/getTargetValue'](ele);
                if (findTarget) {
                  this.bottomList.push({
                    cssclass: 'space_curation_info_box img',
                    url: findTarget.imgUrl
                  });
                }
              });
            }

            // 소재
            if (res.data.result.material) {
              res.data.result.material.forEach(ele => {
                const findMaterial = this.$store.getters['curation/getMaterialValue'](ele);
                if (findMaterial) {
                  this.bottomList.push({
                    cssclass: 'space_curation_info_box material',
                    url: findMaterial.materialNm
                  });
                }
              });
            }

            // 색상
            if (res.data.result.color) {
              const findColor = this.$store.getters['curation/getColorItem'](res.data.result.color);
              this.bottomList.push({
                cssclass: 'space_curation_info_box color',
                url: `${findColor.img}`
              });
            }
          }
          if (this.bottomList.length > 0) {
            this.bottomList[0].cssclass = this.bottomList[0].cssclass + ' ' + 'first';
          }

          // console.log(res.data.result);
          this.tempList = res.data.result;

          if (res.data.result.carMood != null) {
            // 차량
            this.curationInfo.carPurpose = res.data.result.carPurpose;
            this.curationInfo.carMood = res.data.result.carMood;
            this.curationList = [...this.curationInfo.carPurpose, this.curationInfo.carMood];
          } else {
            // 스토어
            this.curationInfo = res.data.result;
            this.curationList = [this.curationInfo.mood, ...this.curationInfo.target, ...this.curationInfo.targetUnit];
          }

          if (res.data.result.domesticRate != null) {
            this.domesticRate = res.data.result.domesticRate;
            this.foreignRate = res.data.result.foreignRate;
            // $('#progress2').css('width', this.domesticRate + '%');
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created () {
    for (let i = 0; i <= 100; i += 10) {
      const item = {
        id: 'domesticRate' + i,
        value: i
      };
      this.countryRateList.push(item);
    }
  },
  mounted () {
    document.querySelector('html').scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    this.getCurationInfo();
    this.checkRegistCard();
    if (this.$store.state.installablePlayer) this.isInstalledPlayer = true;
  }
});
</script>
<style scoped>
.select_curation {
  justify-content: flex-start;
  flex-wrap: wrap;
}

.select_curation p {
  padding: 10px 12px;
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 8px 8px;
  white-space: nowrap;
}

.types_bottom > svg {
  height: 15px;
}

.graph_txt p {
  margin: 0;
  font-size: 14px;
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
<style scoped>
#next_btn:disabled,
#prev_btn:disabled {
  opacity: 0.4;
}
</style>
<style scoped src="@/assets/css/insertspace.css"></style>
